import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "メドレーの開発組織をリードするグループマネージャについて語ってもらいました",
  "date": "2023-06-30T03:26:07.000Z",
  "slug": "entry/2023/06/30/122607",
  "tags": [],
  "hero": "./2023_06_30.png",
  "heroAlt": "メドレーの開発組織をリードするグループマネージャについて語ってもらいました"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`みなさん、こんにちは。エンジニアの新居です。`}</p>
    <p>{`今回のインタビューは前回の `}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2023/04/28/022245"
      }}>{`CTO へのインタビュー`}</a>{`に続いて、メドレーの開発組織についてご紹介していきたいと思います。`}</p>
    <p>{`メドレーでは開発組織をリードするロールとして、CTO の他、「グループマネージャ」(以下、GM)という役職があります。今回は医療プラットフォーム(以下、医療 PF)における開発グループの GM を担う二人に、具体的にどのような役割なのか聞いていきます。`}</p>
    <h1>{`インタビュイー紹介`}</h1>
    <p>{`今回の紹介はインタビュー中で前職からメドレー入社の話を聞いているので、シンプルにお送りします。`}</p>
    <h2>{`平山さん`}</h2>
    <p>{`医療 PF 第一本部 プロダクト開発室 第四開発グループ GM。現在は `}<a parentName="p" {...{
        "href": "https://clinics-cloud.com/"
      }}>{`CLINICS`}</a>{` 基幹システムチームのマネジメントを担当。`}</p>
    <h2>{`岡村さん`}</h2>
    <p>{`医療 PF 第一本部 プロダクト開発室 第三開発グループ GM。現在はレセコン(電子カルテの機能のうち主に会計など診療報酬の計算をするソフトウェア)新規開発チームのマネジメントを担当。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/68679e594c84fbaeed312677d6de2f00/eea4a/note0630_011.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAFnq1KEkB//xAAbEAADAAIDAAAAAAAAAAAAAAAAAQIDERITMv/aAAgBAQABBQLt20slEXzNIkx+f//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AUf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAAAASExAhBB/9oACAEBAAY/AsphcLaG7krf/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARIRAxQVH/2gAIAQEAAT8hbyzgToAK8WU9KUv7AOgcMIM1n//aAAwDAQACAAMAAAAQj+//xAAVEQEBAAAAAAAAAAAAAAAAAAAAAf/aAAgBAwEBPxCof//EABYRAQEBAAAAAAAAAAAAAAAAAAEAIf/aAAgBAgEBPxAdl2//xAAcEAEAAwEAAwEAAAAAAAAAAAABABEhMUFRcYH/2gAIAQEAAT8QKvqUjE6n2Cq0BAl+wPEHpFWZSthr+AVMlGR8i5GT/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note0630 011",
            "title": "集合写真",
            "src": "/static/68679e594c84fbaeed312677d6de2f00/e5166/note0630_011.jpg",
            "srcSet": ["/static/68679e594c84fbaeed312677d6de2f00/f93b5/note0630_011.jpg 300w", "/static/68679e594c84fbaeed312677d6de2f00/b4294/note0630_011.jpg 600w", "/static/68679e594c84fbaeed312677d6de2f00/e5166/note0630_011.jpg 1200w", "/static/68679e594c84fbaeed312677d6de2f00/eea4a/note0630_011.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`左から岡村さん、平山さん、筆者`}</em></p>
    <h1>{`前職までの経歴とメドレー入社の理由について`}</h1>
    <h2>{`二人の経験について`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{`: 早速始めていきますが、まずはお二人の経歴を教えていただきたいと思います。`}</p>
    <p><strong parentName="p">{`平山`}</strong>{`: メドレーは 2 社目の会社となります。前職は SIer で 14~5 年くらい受託開発や客先常駐して開発をしていました。SIer というと場合によっては二次請け・三次請けの開発をしているところもあるんですが、自分は一次請けとして直接顧客と関わる形で業務を行っていました。`}</p>
    <p>{`顧客のサービスをステークホルダーと提案・折衝しながら、技術選定・要件定義から開発、リリースまで行い、そこからの保守運用といった部分までの一連の流れを様々な業態の顧客と関わらせて頂きました。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: ありがとうございます。かなり自社開発と同じ感覚の開発を長年されてきたんですね。それでは、岡村さんお願いします。`}</p>
    <p><strong parentName="p">{`岡村`}</strong>{`: 新卒で外資系の IT コンサルティング会社で IT コンサルタントとしてキャリアを始めました。その中でも特に「情報系」と呼ばれるデータアナリティクスや BI (Business Intelligence) などを取り扱う部門で業務をしていました。領域的には金融・製薬・省庁など堅めの領域を主に担当していたので、いわゆる Web アプリケーションを作るというよりは SQL をガリガリ書いてデータ分析を基に業務改善していく…という感じの業務が多かったです。`}</p>
    <p>{`2 社目はスタートアップ企業の立ち上げフェイズにジョインしました。その会社はいわゆる Insure Tech と呼ばれる保険業界 x テクノロジー領域のサービスを作っている会社でした。そこで、その会社でのプロダクトマネージャ業務と、運転資金を確保するために大手保険会社向けのコンサルタント業務に半々で従事していました。`}</p>
    <p>{`その後にメドレーにジョインするという経歴です。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3cb2b395b9381ee96bb416ebf6cd490b/eea4a/note0630_005.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAAB6E+Di5OR/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIDBDIRM//aAAgBAQABBQJpFC11CAuO2cjUfn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFH/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAECcRBRgv/aAAgBAQAGPwLY1iNnJGj/xAAcEAACAgIDAAAAAAAAAAAAAAABEQAhEDFBcbH/2gAIAQEAAT8hamGpAzmWbUf7PQcqmo9GM//aAAwDAQACAAMAAAAQsB//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAEDAQE/EEsKf//EABYRAQEBAAAAAAAAAAAAAAAAAAEQIf/aAAgBAgEBPxBdj//EABwQAQACAgMBAAAAAAAAAAAAAAEAETFxIVGBkf/aAAgBAQABPxDmTcHYlxgIoxXe5XBraVxe5vhiNq23l8n0hn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note0630 005",
            "title": "岡村さん",
            "src": "/static/3cb2b395b9381ee96bb416ebf6cd490b/e5166/note0630_005.jpg",
            "srcSet": ["/static/3cb2b395b9381ee96bb416ebf6cd490b/f93b5/note0630_005.jpg 300w", "/static/3cb2b395b9381ee96bb416ebf6cd490b/b4294/note0630_005.jpg 600w", "/static/3cb2b395b9381ee96bb416ebf6cd490b/e5166/note0630_005.jpg 1200w", "/static/3cb2b395b9381ee96bb416ebf6cd490b/eea4a/note0630_005.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`岡村さん`}</em></p>
    <h2>{`メドレー入社の経緯について`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{`: ありがとうございます、お二人はメドレーに入社してどのくらい経ちますっけ?`}</p>
    <p><strong parentName="p">{`平山`}</strong>{`: 私は 3 年半ですね。`}</p>
    <p><strong parentName="p">{`岡村`}</strong>{`: 私は 4 年経ちました。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: もうそんなに経つんですね。みなさんの前職を転職しようと思った動機とはどんなものだったんですか?`}</p>
    <p><strong parentName="p">{`平山`}</strong>{`: 前職でプレイングマネージャとして業務をしてきましたが、キャリアを重ねてくると徐々にマネジメントの割合が増えてきます。それ自体は自然なことではあるのですが、前職の延長線上でマネジメントに比重を置くことに対して、危機感を感じていたんです。手を動かす部分を含めて、エンジニアとして良いキャリアを積むには別の環境に身を置く必要があると思い、転職を決意しました。`}</p>
    <p>{`転職に際しては、自分が`}<strong parentName="p">{`身近に感じるサービスで特に自分の家族が関わるような領域が良いと考えており、医療はそうした意味でぴったりな領域`}</strong>{`でした。これらの条件で転職エージェントを通じてメドレーに辿りつきました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fbda0740f907bbf28fae1db051f26f1e/eea4a/note0630_003.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABeVdMSQLP/8QAHBAAAgICAwAAAAAAAAAAAAAAAQIDBAASMTNB/9oACAEBAAEFArLDWABMkTY2O3x+f//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABwQAAIABwAAAAAAAAAAAAAAAAABAhASIjFBgf/aAAgBAQAGPwKl7HDhiuSOT//EABsQAQACAgMAAAAAAAAAAAAAAAEAETFBEHGx/9oACAEBAAE/IR1ldCEUuRJZMDfcxQeOH//aAAwDAQACAAMAAAAQZx//xAAWEQADAAAAAAAAAAAAAAAAAAAQESH/2gAIAQMBAT8QcH//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8QxH//xAAdEAEBAAICAwEAAAAAAAAAAAABEQAxIbFBYaHx/9oACAEBAAE/EHol2SF38wLkxjRE4TC4NTG3b1gG5f3iYb4dZ05//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note0630 003",
            "title": "平山さん",
            "src": "/static/fbda0740f907bbf28fae1db051f26f1e/e5166/note0630_003.jpg",
            "srcSet": ["/static/fbda0740f907bbf28fae1db051f26f1e/f93b5/note0630_003.jpg 300w", "/static/fbda0740f907bbf28fae1db051f26f1e/b4294/note0630_003.jpg 600w", "/static/fbda0740f907bbf28fae1db051f26f1e/e5166/note0630_003.jpg 1200w", "/static/fbda0740f907bbf28fae1db051f26f1e/eea4a/note0630_003.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`平山さん`}</em></p>
    <p><strong parentName="p">{`岡村`}</strong>{`: 先程も少し話しましたが、前職は自社プロダクトと他社向けコンサルティングを並行して運営していた会社でした。しかし、途中から経営方針が変わってコンサルティング業務をメインに据えるようになったんです。自分としては、自社プロダクトを作っている点を大事に考えていたため、転職を考えはじめました。`}</p>
    <p>{`会社選びの軸としては、もちろん自社サービスが事業の主軸である会社というのが一番でした。他の条件としては「上場一歩手前」くらいのフェイズの会社というものがありました。これは、そのくらいのフェイズの会社であれば今までやっていた事業をいきなりピボットするという可能性が限りなく低いのではないかと考えたからです。そんな感じで会社を探していて、LinkedIn の広告経由でメドレーにアプローチして入社しました。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: 岡村さんは次の会社の事業領域は、意識していたんでしょうか?`}</p>
    <p><strong parentName="p">{`岡村`}</strong>{`: 医療領域を名指しで考えていたわけではないです。しかし、いわゆる`}<strong parentName="p">{`昔ながらの慣習などが多く残るような業界や領域を、自分達が手がけたサービスで変えていくというのが良い`}</strong>{`という考えでした。これは前職でも考えていたことだったので、引き続き志向していました。`}</p>
    <h1>{`メドレー入社後にどのような仕事をしていたのか`}</h1>
    <p><strong parentName="p">{`新居`}</strong>{`: ありがとうございます。そんな経緯でメドレーに入社したお二人ですが、入社後はどのような業務をされていたんでしょうか。`}</p>
    <p><strong parentName="p">{`平山`}</strong>{`: 私は入社後から一貫して CLINICS カルテを担当しています。入社後は医療に関する業務知識を身につけながら、周辺業務から徐々に関わっていきシステム面のキャッチアップをしていきました。一番最初に携わったプロジェクトとしては、CLINICS カルテにおける診察業務のコントロールを担う「診療ステータス」の拡張を行うという、カルテ全体に影響のある大きな改修に携わりました。それ以降も、小さいタスクを捌きつつ、中・大規模な施策に携わってきた感じです。`}</p>
    <p>{`そこから医療 PF 横断のプロジェクトにも関わっていくようになりました。CLINICS というプロダクトは患者が使用するアプリを始め、調剤・歯科のサービスも提供しています。`}<strong parentName="p">{`医療 PF 全体の体験設計を踏まえて、周辺プロダクトとも積極的に関わっていく必要がある`}</strong>{`という点は特徴的だと思います。`}</p>
    <p><strong parentName="p">{`岡村`}</strong>{`: 私は入社当時は「社長室コンサル」という役割で入社し、この部署は M&A などをメインに担当していた部署だったんです。そのため最初にアサインされたのが NaCl メディカル社(現在はメドレー本体に統合)の業務面での PMI でした。これと平行して、現在も引き続き携わっている新しいレセコンを作るプロジェクトのプロダクトマネージャを担当してきました。`}</p>
    <p><strong parentName="p">{`新規レセコンは CLINICS カルテと連携しているプロダクトなので、平山さんが率いるチームと連携`}</strong>{`しながら、このプロダクトを作っている開発チームを率いています。`}</p>
    <h1>{`GM としてのミッションと就任してから変わったこと・変わらないこと`}</h1>
    <p><strong parentName="p">{`新居`}</strong>{`: 話を聞いていると、お二人とも最初の仕事を順調にスケールアップしていったという形で現在の業務に繋っているんですね。さて、ここからは GM に就任してからの自身の業務がどのように変化してきたのかという点を聞きたいのですが、この点どうでしょうか。`}</p>
    <p><strong parentName="p">{`平山`}</strong>{`: ミッションはやはり変化しました。GM としてのミッションで一番求められるものは「`}<strong parentName="p">{`プロダクトを前進させる`}</strong>{`」という部分と考えています。自身の管掌組織は当然ですが、組織を横断する形の取り組みも必要になります。`}</p>
    <p>{`「ピープルマネジメント」という点も GM としての変化の１つかなと思います。とはいえ、先ほどの話と通じる部分はあり`}<strong parentName="p">{`「プロダクトに軸足を置いて目線を合わせていくこと」が基本`}</strong>{`となります。また、組織上の持続可能性という意味においても「任せていく」というところは大事にしています。メンバーに優秀な方が多いので助けられている部分は多いと感じます。`}</p>
    <p>{`エンジニアリングという意味では、手を動かす量は減りました。手の動かし方という意味では入社当初から横の動きを見るようにはしていたので GM になって変わったという部分は無いですね。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4ffbcc427b882dc84a19fe86c4c86710/eea4a/note0630_008.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAWsNI1OWT//EABsQAAIBBQAAAAAAAAAAAAAAAAIDMgEREiFC/9oACAEBAAEFAtMc0q5CY2THsI//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAABBAMAAAAAAAAAAAAAAAAAAQIDMRAScf/aAAgBAQAGPwJJDVtlijuY/8QAGxABAAEFAQAAAAAAAAAAAAAAASEAEBExQVH/2gAIAQEAAT8h8poqUEcwZitoJsRynbb/2gAMAwEAAgADAAAAEJPP/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAh/9oACAEDAQE/EHAn/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAh/9oACAECAQE/EDWf/8QAHRABAAMAAgMBAAAAAAAAAAAAAQARIUFxMVGhsf/aAAgBAQABPxDTNR31KuurlActjQdwNuUH5+jEpqoWlxFleRd8swAw9E//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note0630 008",
            "title": "GM",
            "src": "/static/4ffbcc427b882dc84a19fe86c4c86710/e5166/note0630_008.jpg",
            "srcSet": ["/static/4ffbcc427b882dc84a19fe86c4c86710/f93b5/note0630_008.jpg 300w", "/static/4ffbcc427b882dc84a19fe86c4c86710/b4294/note0630_008.jpg 600w", "/static/4ffbcc427b882dc84a19fe86c4c86710/e5166/note0630_008.jpg 1200w", "/static/4ffbcc427b882dc84a19fe86c4c86710/eea4a/note0630_008.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`岡村`}</strong>{`: レセコン新規開発プロジェクトでの GM のミッションとして、「プロジェクトの完遂」、「チームメンバーのピープルマネジメント」、「Tech Studio MATSUE(島根県にあるメドレーの拠点。レセコン新規開発プロジェクトの開発をしている)の管理」がメインになります。`}</p>
    <p>{`GM に就任したタイミングは NaCl メディカル社がメドレー本体に吸収合併されたというタイミングでした。就任前はグループ会社ということもあり、開発業務を受発注するという動きに近い感じで協業してきました。GM になってからは、同じ会社になったのでより近い距離でチーム開発をするようになったり、メンバーのピープルマネジメントの割合が多くなるという変化がありました。`}</p>
    <p>{`担当領域としてはレセコンのプロダクトマネジメントをしているという部分は変わらない部分です。また平山さんがおっしゃっていたように、いつまでも自分が上にいて指揮するという状態を良しとするのではなく、`}<strong parentName="p">{`メンバーの人をどんどんと上のロールに引き上げていくというのが GM の役割の 1 つ`}</strong>{`かと思うので、そういう意識でメンバーのマネジメントをしています。`}</p>
    <p>{`エンジニアリングとマネジメントの比率はプロダクトのフェイズによって変わってくるし、変わるべきかなと思っています。現在は 8 対 2 でマネジメントの比率が多くなっています。一方でプロダクトの初期でどんどんと開発をしていかないといけないという場合には自分も含めて人手をかけて開発していくようにします。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: お話をうかがうと、やはり GM 前よりもマネジメントの比率が多くなっているのではないかと思うのですが、葛藤などはありましたか。`}</p>
    <p><strong parentName="p">{`平山`}</strong>{`: 元々の自分のスタンスとして「自分も駒の一つ」としてプロダクト開発を推進していくという思考があります。GM の話が来たときにも、やれるかなという不安感はありましたが、今の場面においては自身がやった方が良いと感じて受けました。`}</p>
    <p><strong parentName="p">{`岡村`}</strong>{`: 私は出自がエンジニアではないというのもあって、葛藤のようなものも平山さんとは違って、特にありませんでした。元々 NaCl メディカル社だった松江オフィスとのハブになるのは、自分しかいないと思っていましたので躊躇のようなものも全く無かったです。`}</p>
    <h1>{`GM で苦労したポイント・どう克服していたか`}</h1>
    <p><strong parentName="p">{`新居`}</strong>{`: ありがとうございます。では、GM になって苦労したとか大変だったというところってどんなところがありましたか。`}</p>
    <p><strong parentName="p">{`平山`}</strong>{`: 自分は口下手な方なんで、以前より喋る機会が増えたのが困ったところですね（笑）`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: 平山さんに口下手な印象は全然ありませんが（笑）`}</p>
    <p><strong parentName="p">{`平山`}</strong>{`: 他には、これはどちらかというと PdM 属性の仕事にも関わってくるんですが、開発計画の策定は大変です(メドレーでは 3 ヶ月ごとに開発計画を立てている)。`}</p>
    <p>{`大規模な施策の実施と並行して優先度の高いタスクの差し込みを踏まえて、優先度の再設計や施策のフェージングや運用の検討したりしています。`}<strong parentName="p">{`自分で抱え込む必要はないですが、関係者と調整しつつも最終意思決定は自分になるので、様々な葛藤をしながら取り組んでいます`}</strong>{`。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: ピープルマネジメント面では何かありましたか。`}</p>
    <p><strong parentName="p">{`平山`}</strong>{`: 先程お話した「プロダクトに軸足を置いて目線を合わせていくこと」を大切にコミュニケーションを実施し、日々の業務を通してメンバー一人一人でシナジーが出る・良いキャリアが重ねられるようにしたいと考えていますが、先ほどの開発計画の難しさもここにあります。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: ありがとうございます。では岡村さんはいかがでしょう。`}</p>
    <p><strong parentName="p">{`岡村`}</strong>{`: 平山さんに全く同感ですね（笑）`}</p>
    <p>{`冗談は置いておいて、計画外の差し込みタスクなどが入ると、どうしてもチームの負荷が高くなってくるので、そこをコントロールしきれずに申し訳ないなと思うことがあります。`}</p>
    <p>{`他には、メンバーのキャリア形成をどうするかという点が難しいと思います。`}<strong parentName="p">{`メンバーの志向に合わせての将来像を見据えて現在の業務に取り組んでもらいたい`}</strong>{`のですが、実際のタスクとそうしたキャリアの方向性が必ずしもぴったりと合うときばかりではないので、ここをどう揃えていくかという点にも苦労はしています。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: なるほど、岡村さんの場合は元々として島根と東京という立地間でのマネジメントをされていたと思うんですが、リモートコミュニケーションが中心という環境での苦労は何かありましたか。`}</p>
    <p><strong parentName="p">{`岡村`}</strong>{`: 実はこれと言って無いんですよ。それでもちゃんと上手く機能している要因としては、2 つあります。1 つは島根の方に現地のメンバーを取りしきってくれるスキルを持ったメンバーが居てくれたことです。ここは今でも本当に助かっています。`}</p>
    <p>{`また、当初から技術的な部分も含めて私もメンバーと一緒に勉強しながら、新しい取り組みをするというスタンスを持ってプロジェクトを推進できたことです。ですので、離れたところから命令する人のような感じではなく、`}<strong parentName="p">{`チームで一緒にプロジェクトを進める人という認識を持ってもらえた`}</strong>{`んじゃないかなと考えています。`}</p>
    <p>{`もちろん、関係値を作れるまでは私が最初の頃は 1 ヶ月に 1~2 回程度島根に出張して、実際にメンバーと会ったりすることもしていましたが。`}</p>
    <h1>{`GM を担う上で、役に立った経験・知識`}</h1>
    <p><strong parentName="p">{`新居`}</strong>{`: ここからは、お二人が今までの業務の中で積んできた経験・知識の内、現在 GM として活動する上で役に立っているものとして、どんなものがあるのか聞ければと思います。`}</p>
    <p><strong parentName="p">{`平山`}</strong>{`: そうですね、一番役に立っているなと感じているのは前職で経験した「`}<strong parentName="p">{`様々なステークホルダーとの主体性を持った折衝経験`}</strong>{`」です。色々な会社や立場の方とコミュニケーションを取ることが多かったんですね。専門性もそれぞれ違う方達とお話して物事を進める経験ができたことは、今の GM という役割に本当に役立っていると思います。`}</p>
    <p><strong parentName="p">{`岡村`}</strong>{`: 私が役に立っていると感じる経験は、「`}<strong parentName="p">{`一回深く細かいところまで業務を見てみて理解をしてから、改めて俯瞰した視点で業務を見直してメンバーにお願いする`}</strong>{`」という習慣です。この相反する方法をバランス良くできるようになっているというのが、良い点なのかなと思っています。また、深く見るというのは自分が興味を持っている分野でないと中々難しいので、何にでも興味を持って業務に取り組むという心持ちも必要になるかと考えています。`}</p>
    <p>{`こうした形で業務を分解してメンバーにお願いできるところはしながら、GM としては適切に権限委譲していくというのに役立っています。`}</p>
    <h1>{`エンジニアのピープルマネジメントで意識していること`}</h1>
    <p><strong parentName="p">{`新居`}</strong>{`: 今までのお話でも少し出ている話題になるのですが、GM としてエンジニアのマネジメントで意識しているポイントって、どんなことがありますか。`}</p>
    <p><strong parentName="p">{`平山`}</strong>{`: 自分達が作る`}<strong parentName="p">{`プロダクトへの興味関心・解像度を高め、何を作るのか`}</strong>{`。という点において各所コミュニケーションを含めて主体的に動くこと。そうした積み重ねの延長線上にあるエンジニアとしてのキャリア形成を意識しています。`}</p>
    <p>{`この考え方に加えて「プロダクトの持続可能性を高める」というテーマで、エンジニア・デザイナー・ディレクター・ QA エンジニア・カスタマーサクセスといったプロダクトに関わる人達の専門性を踏まえたタスクの再配布・プロジェクト設計を行う。といった体制作りも推進しようとしています。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: 「プロダクトの持続可能性を高める」というのは、すごく難しい試みですよね。`}</p>
    <p><strong parentName="p">{`平山`}</strong>{`: 確かにとても難しいなと思います。でも打ち手としては色々あるのではないかと考えていて。「属人性の排除」といった点は、分かりやすいのではないかなと思います。誰かに依存した状態は、組織変更や事業スケールの変化に弱くなりがちですよね。`}</p>
    <p>{`大事なのは「人から組織への移行」「仕組み化」と捉えています。「できる人を増やす」については、大事だけど秘伝のタレが受け継がれるだけになってしまうので、持続可能性の観点では本質的ではないかなと。`}</p>
    <p>{`一方で「仕組み化するための計画的な属人性」については推奨できると考えてます。新しいことをいきなり「組織に転換」や「人を増やしてローテする」だと、ナレッジが蓄積されづらく、次の一手が打ちづらくなるので。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: そうした取り組みをするのに、例えばエンジニアだと作ってみたものがプロダクトの目指す方向性とズレが生じていたというような事態があると思うんですが、そうしたズレを事前に仕組みで抑えるような事をしているんですか。`}</p>
    <p><strong parentName="p">{`平山`}</strong>{`: きっちりとした仕組みなどは作ってはいないです。仕組み化というよりも、そうならないように`}<strong parentName="p">{`メンバーから上がってきた疑問などに対する壁打ちなどは気軽にできる`}</strong>{`ようにしています。また、そういった相談を気軽にできるような自分自身のプロデュースといいますか、キャラ作りみたいなものも含めてメンバーが相談しにくいという空気を作らないようにはしていますね。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: ありがとうございます。岡村さんはどんな意識をしていますか。`}</p>
    <p><strong parentName="p">{`岡村`}</strong>{`: やっぱり平山さんに全く同感ですね（笑）`}</p>
    <p>{`付け加えると、ソフト面ですが`}<strong parentName="p">{`メンバーであるエンジニアが楽しめる環境作り`}</strong>{`というところを意識しています。楽しさにも色々な種類があるとは思いますが、単にコーディングするだけではなく、やはりプロダクトそれ自体の提供価値というものをしっかりと共有して、プロダクトを作ること自体に楽しさを感じてもらう…というのは重要じゃないかなと思っています。`}</p>
    <p>{`また楽しさの別側面ですが、新しいテクノロジーなどもどんどんと取り入れることができるような仕組み作りもしています。島根のメンバーは今回のプロジェクトで初めて Ruby を使うというメンバーも多かったので、ここで楽しさを感じられないと、こうしたテクノロジー自体を嫌いになってしまう恐れがあるなと思ったので。`}</p>
    <p>{`そうした「楽しい仕事」にしていくと「時間をかけなきゃ」ではなく「時間をかけたい」という感じで取り組めるようになると思います。`}</p>
    <h1>{`GM という役割の醍醐味とは`}</h1>
    <p><strong parentName="p">{`新居`}</strong>{`: それでは終盤になってきましたが、GM の「やりがい」や「醍醐味」はどういったものになりますか。`}</p>
    <p><strong parentName="p">{`岡村`}</strong>{`: 一番は「ピープルマネジメント」になるんじゃないかと考えています。最近、岸田首相の所信表明演説で聞いて感銘を受けたアフリカの諺ですが「早く行きたいなら 1 人で行け、遠くへ行きたいならみんなで行け」という言葉はピープルマネジメントの本質なんじゃないかなと思っています。`}<strong parentName="p">{`メンバーと一緒に遠くに行くために組織作り`}</strong>{`などをしていけるのが醍醐味だと感じています。`}</p>
    <p><strong parentName="p">{`平山`}</strong>{`: メンバーの`}<strong parentName="p">{`個人スキルを高めつつ「チーム」に還元してもらうための仕組み`}</strong>{`を作っていく。というのは、醍醐味と言えるのかなと。CTO インタビューで田中も言っていますが「チームバリュー」は大切だと考えています。`}</p>
    <p>{`チームといっても、狭義のチームではなくプロダクトに関わる関係者全員を含めたチームという感じで意識しています。開発組織に閉じないバリューの発揮がメンバーそれぞれでできると心強いと思います。`}</p>
    <h1>{`メドレーで GM やリードエンジニアのロールに向いている方`}</h1>
    <p><strong parentName="p">{`新居`}</strong>{`: 最後になるのですが、メドレーで GM やテックリードといったロールに向いているのは、どんな人だと思いますか。`}</p>
    <p><strong parentName="p">{`平山`}</strong>{`: 基本的に`}<strong parentName="p">{`物事を前に進めるための解像度を高く描ける人`}</strong>{`かなと思っています。何を誰のためにというのを体現しつつ、関係者を引っぱっていける人ですね。メドレーの `}<a parentName="p" {...{
        "href": "https://www.medley.jp/team/culture.html"
      }}>{`Our Essentials`}</a>{` 全般必要だとは思いますが、その中でも「成果を出す」「自分をアップデート」「組織水準を高める」「革新と改善を主導」というところが必要になるかな。困難な状況でも結果を出しつつ、自分の行動も高めていき、結果組織・プロダクトを良くしていける…というのがメドレーのリードと呼ばれているエンジニアかと思います。`}</p>
    <p>{`やり方としては、マイクロマネジメントではなく、基本となる考え方をちゃんと伝えてメンバーに学んでもらう…という、やり方ができる人が向いていると思います。`}</p>
    <p><strong parentName="p">{`岡村`}</strong>{`: `}<strong parentName="p">{`困難な状況であっても何とかして前に進めることができる人`}</strong>{`でしょうか。無理矢理に進めるというわけではなく、状況の整理や色々な部署との調整などもしたりして、何とか結果という形にしていける能力がある人がリードとしてメンバーを引っぱっていける人だと思います。`}</p>
    <p>{`もちろん、フェイズによってメンバーとの関わりなどはちゃんと調整していける柔軟さなども必要になると思います。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: なるほど。本日は色々なお話をしていただきまして、ありがとうございました!`}</p>
    <h1>{`さいごに`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8ba7473e1f109c227ffca13d7fff672c/eea4a/note0630_010.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABb4ly7kCf/8QAGxAAAgMAAwAAAAAAAAAAAAAAAQIAAxESEyL/2gAIAQEAAQUCX3MtVe8iaQHZjKwOP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/AYf/xAAaEAADAQADAAAAAAAAAAAAAAAAASECEBEx/9oACAEBAAY/Ak1WXMJ0el03x//EABkQAQADAQEAAAAAAAAAAAAAAAEAESExcf/aAAgBAQABPyG4x4nkbLk6k4rTyLCIfZShGYsRsJ//2gAMAwEAAgADAAAAEDgf/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Qp//EABcRAAMBAAAAAAAAAAAAAAAAAAABEVH/2gAIAQIBAT8QTaQ//8QAGxABAQACAwEAAAAAAAAAAAAAAREAITFRYXH/2gAIAQEAAT8QdYjHOzrXT3lzoSzJ9werRSXPK7wgUeRDg8y7NOfcEKmu0z//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note0630 010",
            "title": "集合写真 2",
            "src": "/static/8ba7473e1f109c227ffca13d7fff672c/e5166/note0630_010.jpg",
            "srcSet": ["/static/8ba7473e1f109c227ffca13d7fff672c/f93b5/note0630_010.jpg 300w", "/static/8ba7473e1f109c227ffca13d7fff672c/b4294/note0630_010.jpg 600w", "/static/8ba7473e1f109c227ffca13d7fff672c/e5166/note0630_010.jpg 1200w", "/static/8ba7473e1f109c227ffca13d7fff672c/eea4a/note0630_010.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`メドレーの GM 二人に仕事について聞いていきました。`}</p>
    <p>{`前回の CTO インタビューでも語られた開発組織の話ですが、より現場に近い GM という立場のメンバーからの話でまた違った雰囲気などを感じていただけたかと思います。`}</p>
    <p>{`このような GM を「ぜひやりたい!」という方や「こんな上司の元で自分の力を発揮していきたい!」という方はぜひカジュアルにお話ができればと思います。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      